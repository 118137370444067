// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-coming-soon-tsx": () => import("./../../../src/pages/coming-soon.tsx" /* webpackChunkName: "component---src-pages-coming-soon-tsx" */),
  "component---src-pages-style-guide-tsx": () => import("./../../../src/pages/style-guide.tsx" /* webpackChunkName: "component---src-pages-style-guide-tsx" */),
  "component---src-templates-page-about-js": () => import("./../../../src/templates/page/about.js" /* webpackChunkName: "component---src-templates-page-about-js" */),
  "component---src-templates-page-default-js": () => import("./../../../src/templates/page/default.js" /* webpackChunkName: "component---src-templates-page-default-js" */),
  "component---src-templates-page-facility-js": () => import("./../../../src/templates/page/facility.js" /* webpackChunkName: "component---src-templates-page-facility-js" */),
  "component---src-templates-page-gallery-js": () => import("./../../../src/templates/page/gallery.js" /* webpackChunkName: "component---src-templates-page-gallery-js" */),
  "component---src-templates-page-history-js": () => import("./../../../src/templates/page/history.js" /* webpackChunkName: "component---src-templates-page-history-js" */),
  "component---src-templates-product-default-js": () => import("./../../../src/templates/product/default.js" /* webpackChunkName: "component---src-templates-product-default-js" */)
}

