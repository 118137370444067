const themeUi = {
  headerHeight: 94,
  breakpoints: ["40em", "52em", "64em"],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: "Barlow, sans-serif",
    heading: "Barlow,  sans-serif",
  },
  fontSizes: [
    11, 12, 14, 16, 18, 20, 22, 24, 28, 30, 32, 36, 38, 44, 46, 48, 52,
  ],
  fontWeights: {
    light: 300,
    medium: 400,
    body: 400,
    heading: 700,
    bold: 700,
    md: 500,
    semiBold: 600,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  colors: {
    bgDark: "#253746",
    bgGreen: "#00943C",
    charcoalLight: "#3F576E",
    charcoal: "#2D4052",
    charcoalDark: "#22303D",
    almondLight: "#F5F4F1",
    almond: "#DDDAD7",
    almondDark: "#C4BFBB",
    coralLight: "#FF7B59",
    coral: "#E86140",
    coralDark: "#AD4930",
    flaxLight: "#F0E3AC",
    flax: "#E5D282",
    flaxDark: "#AB9D61",
    turquoiseLight: "#A8E6E4",
    turquoise: "#66D7D1",
    turquoiseDark: "#4DA39F",
    plumLight: "#9083B2",
    plum: "#756999",
    plumDark: "#564F78",
    black: "#000000",
    black75: "#414141",
    black50: "#848484",
    black25: "#DBDBDB",
    black10: "#F7F7F7",
    white: "#FFFFFF",
    critical: "#DE0000",
    criticalLight: "#EBC7C4",
    success: "#6BAB33",
    successLight: "#DEF2D6",
    warning: "#FFD905",
    warningLight: "#F7F2D6",
    notice: "#253746",
    noticeLight: "#E3E3E3",
    copyRightText: "#8c8c8c",
    facilityHeader: "#9F9F9F",
  },
  text: {
    heading: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "charcoal",
    },
    introduction: {
      fontFamily: "body",
      fontSize: 3,
      fontWeight: "light",
      letterSpacing: "-0.27px",
      color: "black75",
      "@media (max-width: 767px)": {
        letterSpacing: "-0.24px",
        fontSize: "16px",
      },
    },
    paragraph: {
      fontFamily: "body",
      fontWeight: 400,
      fontSize: [2, 3],
      letterSpacing: "-0.23px",
      color: "black75",
    },
    body: {
      fontFamily: "body",
      fontWeight: "body",
      fontSize: 3,
      lineHeight: "24px",
      letterSpacing: "0.01em",
      color: "black75",
    },
    footerHeading1: {
      fontFamily: "body",
      fontSize: 3,
      color: "white",
      fontWeight: "medium",
      letterSpacing: "-0.15px",
    },
    footerHeading2: {
      fontFamily: "body",
      fontSize: 1,
      letterSpacing: "-0.12px",
      color: "black50",
    },
    footerParagraph: {
      fontWeight: "medium",
      lineHeight: "24px",
      fontSize: [2, 2, 3],
    },
    desktopSubMenuItem: {
      fontSize: 7,
      fontFamily: "body",
      fontWeight: "light",
      letterSpacing: "-0.9px",
      color: "almond",
      "&:hover": {
        color: "coral",
      },
    },
    mobileMenuItem: {
      fontFamily: "body",
      fontSize: 2,
      fontWeight: "medium",
    },
    productMenuItem: {
      variant: "text.heading",
      fontSize: ["20px", "20px", "32px"],
      color: "white",
      fontWeight: 500,
      fontStyke: "normal",
      textTransform: "lowercase !important",
    },
    productHeadline: {
      variant: "text.heading",
      letterSpacing: "1px",
      fontWeight: 400,
      fontSize: [11, 13, 14],
    },
    footerLegal: {
      fontFamily: "body",
      color: "copyRightText",
      fontSize: [0, 1, 2],
      textTransform: "uppercase",
      letterSpacing: "0.07em",
    },
    sectionHeading: {
      variant: "text.heading",
      color: "white !important",
      fontSize: ["36px", "40px", "44px"],
      lineHeight: "50px",
      textTransform: "capitalize",
      fontWeight: 500,
    },
    contactHeading: {
      variant: "text.heading",
      color: "bgDark !important",
      fontSize: "18px",
      lineHeight: "24px",
      textTransform: "uppercase",
      fontWeight: 700,
      letterSpacing: "0.04em",
    },
    accordionHead: {
      variant: "text.heading",
      fontSize: ["20px", "20px", "32px"],
      color: "white",
      fontWeight: 500,
      fontStyke: "normal",
      lineHeight: "38px",
    },
  },
  buttons: {
    primary: {
      textDecoration: "none !important",
      fontWeight: "md",
      textTransform: "uppercase",
      fontFamily: "body",
      fontSize: [2, 3, 4],
      letterSpacing: "-0.18px",
      color: "white",
      bg: "transparent",
      border: "1px solid white",
      borderRadius: 6,
      width: ["150px", "150px", "220px"],
      height: 48,
      transition: "box-shadow 0.2s",
      "&:hover": {
        bg: "none",
        boxShadow: "0px 10px 20px #00000029",
      },
      "&:focus": {
        bg: "white",
        color: "black",
      },
      "&:disabled": {
        bg: "black25",
      },
    },
    navButton: {
      bg: "transparent",
      color: "#1C1C1C",
      fontSize: 3,
      fontFamily: "body",
      lineHeight: "body",
      textDecoration: "none !important",
    },
    icon: {
      color: "coral",
    },
    entryfield: {
      field: {
        fontSize: 3,
        lineHeight: "25px",
        width: 304,
        height: 37,
        border: "1px solid",
        borderColor: "black25",
        bg: "white",
        borderRadius: "6px",
        p: "10px 15px",
        fontFamily: "body",
        fontWeight: 300,
        outline: "none",

        "&:placeholder": {
          color: "black50",
          letterSpacing: "-0.23px",
          fontSize: "15px",
          lineHeight: "25px",
        },
        "&:hover": {
          borderColor: "black50",
        },
        ":focus": {
          borderColor: "coralLight",
        },
        "&:disabled": {
          border: "black25",
          color: "black10",
        },
      },
    },
  },
  forms: {
    checkbox: {
      width: "23px",
      height: "23px",
      backgroundColor: "white",
      border: "1px solid",
      borderColor: "black25",
      color: "coral",

      "&:hover": {
        borderColor: "coral",
      },
    },
  },
  links: {
    hyperlink: {
      textDecoration: "none !important",
      textTransform: "uppercase",
      color: "white",
      letterSpacing: "0.07em",
      fontSize: 3,
      fontFamily: "body",
      "@media (max-width: 767px)": {
        fontSize: 2,
      },
      "&:hover": {
        color: "notice",
      },
      "&:focus": {
        color: "notice",
      },
      "&:disabled": {
        color: "black25",
      },
    },
    emaillink: {
      color: "white",
      letterSpacing: "0.07em",
      fontSize: 3,
      fontFamily: "body",
      "@media (max-width: 767px)": {
        fontSize: 2,
      },
      "&:hover": {
        color: "notice",
      },
      "&:focus": {
        color: "notice",
      },
      "&:disabled": {
        color: "black25",
      },
    },
    clickListMenu: {
      fontFamily: "body",
      fontSize: 10,
      fontWeight: "light",
      letterSpacing: "-0.9px",
      color: "almond",
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      a: {
        variant: "links.hyperlink",
        textDecoration: "none !important",
      },
      h1: {
        variant: "text.heading",
        letterSpacing: "1px",
        fontWeight: 500,
        fontSize: [11, 13, 14],
      },
      h2: {
        variant: "text.heading",
        fontSize: 11,
        letterSpacing: "-1.15px",
        "@media (max-width: 767px)": {
          fontSize: 8,
        },
      },
      h3: {
        variant: "text.heading",
        fontWeight: 500,
        fontSize: [9, 9, 10],
        lineHeight: "38px",
        fontStyle: "normal",
      },
      p: {
        variant: "text.body",
        fontSize: 3,
        color: "black75",
        fontWeight: 300,
      },
      h4: {
        variant: "text.heading",
        fontSize: 7,
      },
      h5: {
        variant: "text.heading",
        fontSize: 4,
        letterSpacing: "-0.64px",
        lineHeight: "32px",
      },
      h6: {
        variant: "text.heading",
        fontSize: 4,
      },
    },
    heroHeading: {
      variant: "text.heading",
      fontSize: [8, 9, 13],
      lineHeight: "52px",
      fontWeight: 600,
      fontStyle: "normal",
      "@media (max-width: 1180px)": {
        fontSize: [8, 9, 12],
      },
    },
    accordionTitle: {
      fontSize: [1, 1, 2, 3, 4],
      color: "white",
      textTransform: "uppercase",
      fontWeight: "bold",
      lineHeight: "24px",
      letterSpacing: "1px",
    },
    accheading: {
      variant: "text.heading",
      fontSize: 6,
      letterSpacing: "-0.33px",
      lineHeight: "32px",
      color: "charcoal",
      "@media (max-width: 767px)": {
        fontSize: 5,
        letterSpacing: "-0.4px",
        lineHeight: "22px",
      },
    },
    sec4Pheading: {
      fontWeight: 400,
      fontSize: [7, 8, 15],
      color: "white",
      textTransform: "uppercase",
      fontStyle: "normal",
      lineHight: "52px",
    },
    addheading: {
      variant: "text.heading",
      fontSize: 4,
      letterSpacing: "-0.18px",
      color: "charcoal",
      lineHeight: "26px",
      "@media (max-width: 767px)": {
        fontSize: "16px",
        letterSpacing: "-0.16px",
        lineHeight: "26px",
      },
    },
    pre: {
      fontFamily: "monospace",
      overflowX: "auto",
      code: {
        color: "inherit",
      },
    },
    code: {
      fontFamily: "monospace",
      fontSize: "inherit",
    },
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0,
    },
    th: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    td: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
  },
}

export default themeUi
